<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <Entry title="5M Sprint"
               img_name="5m-sprint.png"
               yt_video_id="WiuSjGW8BXU"
               description="Sprint 5 meters as fast as you can! Starting position: With your foot by the starting line."/>
        <Entry title="10M Sprint"
               img_name="10m-sprint.png"
               yt_video_id="gdVMX5wajQA"
               description="Sprint 10 meters as fast as you can! Starting position: With your foot by the starting line."/>
        <Entry title="16M Sprint"
               img_name="16m-sprint.png"
               yt_video_id="hyE9LnjBBFc"
               description="Sprint from gate A to gate B as quickly as possible. Start with your feet by the starting line (gate A)."/>
        <Entry title="20M Sprint"
               img_name="20m-sprint.png"
               yt_video_id="dHnJs-LW_cs"
               description="Sprint 20 meters as fast as you can! Starting position: With your foot by the starting line."/>
        <Entry title="30M Sprint"
               img_name="30m-sprint.png"
               yt_video_id="eDLNbEoBjcI"
               description="Sprint 30 meters as fast as you can! Starting position: With your foot by the starting line."/>
      </div>
    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "SpeedPage",
  components: {
    Entry
  }
}
</script>
